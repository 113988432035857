import { useEffect, useState } from 'react';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationFR from "./locales/fr/translation.json";
import translationNL from "./locales/nl/translation.json";
import translationEN from "./locales/en/translation.json";
import translationLB from "./locales/lb/translation.json";

const resources = {
  fr: {
    translation: translationFR,
  },
  nl: {
    translation: translationNL,
  },
  en: {
    translation: translationEN,
  },
  lb: {
    translation: translationLB,
  },
};

const defaultCountry = localStorage.getItem('language') ? localStorage.getItem('language') : "fr"
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng:defaultCountry, 
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });
  
export default i18n;