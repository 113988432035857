import ReactDOM from 'react-dom/client';
import { LicenseInfo } from '@mui/x-license-pro';
import { useNavigate } from 'react-router-dom';
import { request } from './services/api-request';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import "./i18n"
// ----------------------------------------------------------------------

LicenseInfo.setLicenseKey('52e48259284f2e5e75c57f4f301a20a7Tz04MTYxMixFPTE3MzYwNzA2NjMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');


const root = ReactDOM.createRoot(document.getElementById('root'));

const storedUser = JSON.parse(localStorage.getItem('user'));
if(storedUser) {
    const requestResult = await request('GET', `WebUser/${storedUser.id}`);
    const WebUserDTO = requestResult.data;
    if (WebUserDTO && WebUserDTO.forceReload) {
        localStorage.removeItem('user');
        window.location.reload();

        WebUserDTO.ForceReload = false;
        await request('PUT', `WebUser/${WebUserDTO.id}`, WebUserDTO);
    }
}

root.render(<App />);

serviceWorker.unregister();

reportWebVitals();
